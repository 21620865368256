<template>
  <div class="app-view category-view">
    <!-- <header>
      <div class="top-head">
        <h1 class="logo">Bills91</h1>
      </div>

      <ul class="category-tab-blk">
        <li class="active-category">{{$t('_pay')}}</li>

        <li>Recent Transaction</li>
      </ul>
    </header> -->
    <section class="mid-cnt" v-if="(category || refillItem) && isLoad">
      <div class="type-category-blk">
        <div class="type-title-blk">
          <span class="icon-btn back-btn" @click="$router.push('/')">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="40px"
              viewBox="0 0 24 24"
              width="40px"
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path
                d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z"
              />
            </svg>
          </span>
          <span class="ctg-icon">
            <img :src="image" alt="" />
          </span>
          <span class="ctg-title"> {{ category_name }} </span>
        </div>
        <div class="ctg-type-outer">
          <div class="type-form-box">
            <div class="input-field-row" @click="handleOperator">
              <label class="txt-label">Select {{ category_name }}</label>
              <input
                v-if="selectedOperator == null"
                type="text"
                name="select_operator"
                value=""
                :placeholder="$t('select_operator')"
                class="input_bg"
                readonly
              />
              <span class="input_bg" v-else>{{
                selectedOperator.BillerName
              }}</span>
              <!-- <select
                name="select_operator"
                class="input_bg"
                v-model="selectedOperator"
              >
                <option class="default" :value="null" selected disabled>
                  Select Operator
                </option>
                <option
                  :value="operator"
                  v-for="(operator, i) in operators"
                  :key="i"
                >
                  {{ operator.BillerName }}
                </option>
              </select> -->

              <span class="type-icon drop-icon">
                <svg
                  width="16"
                  height="10"
                  viewBox="0 0 16 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.28571 0L8 5.71429L13.7143 0L16 1.14286L8 9.14286L0 1.14286L2.28571 0Z"
                  />
                </svg>
              </span>
            </div>

            <div
              class="input-field-row"
              v-if="billerParams && selectedOperator"
            >
              <label class="txt-label"
                >{{ billerParams.Name || "Enter Your Number" }}
              </label>
              <input
                type="text"
                name="mobile_number"
                value=""
                :placeholder="'Enter ' + (billerParams.Name || 'Your Number')"
                class="input_bg"
                @blur="handleBlur"
                v-model="billerFieldVal"
              />

              <!-- <span class="type-icon contact-icon">
                <svg
                  width="21"
                  height="25"
                  viewBox="0 0 21 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 15C10.6875 15 11.2763 14.755 11.7663 14.265C12.2554 13.7758 12.5 13.1875 12.5 12.5C12.5 11.8125 12.2554 11.2237 11.7663 10.7337C11.2763 10.2446 10.6875 10 10 10C9.3125 10 8.72417 10.2446 8.235 10.7337C7.745 11.2237 7.5 11.8125 7.5 12.5C7.5 13.1875 7.745 13.7758 8.235 14.265C8.72417 14.755 9.3125 15 10 15ZM5 20H15V19.2812C15 18.7812 14.8646 18.3229 14.5938 17.9062C14.3229 17.4896 13.9479 17.1771 13.4688 16.9688C12.9271 16.7396 12.37 16.5625 11.7975 16.4375C11.2242 16.3125 10.625 16.25 10 16.25C9.375 16.25 8.77583 16.3125 8.2025 16.4375C7.63 16.5625 7.07292 16.7396 6.53125 16.9688C6.05208 17.1771 5.67708 17.4896 5.40625 17.9062C5.13542 18.3229 5 18.7812 5 19.2812V20ZM17.5 25H2.5C1.8125 25 1.22417 24.7554 0.735 24.2663C0.245 23.7763 0 23.1875 0 22.5V2.5C0 1.8125 0.245 1.22375 0.735 0.73375C1.22417 0.244583 1.8125 0 2.5 0H11.4688C11.8021 0 12.12 0.0625001 12.4225 0.1875C12.7242 0.3125 12.9896 0.489583 13.2188 0.71875L19.2812 6.78125C19.5104 7.01042 19.6875 7.27583 19.8125 7.5775C19.9375 7.88 20 8.19792 20 8.53125V22.5C20 23.1875 19.7554 23.7763 19.2663 24.2663C18.7763 24.7554 18.1875 25 17.5 25Z"
                    fill="#242424"
                  />
                  <path
                    d="M10 15C10.6875 15 11.2763 14.755 11.7663 14.265C12.2554 13.7758 12.5 13.1875 12.5 12.5C12.5 11.8125 12.2554 11.2237 11.7663 10.7337C11.2763 10.2446 10.6875 10 10 10C9.3125 10 8.72417 10.2446 8.235 10.7337C7.745 11.2237 7.5 11.8125 7.5 12.5C7.5 13.1875 7.745 13.7758 8.235 14.265C8.72417 14.755 9.3125 15 10 15ZM5 20H15V19.2812C15 18.7812 14.8646 18.3229 14.5938 17.9062C14.3229 17.4896 13.9479 17.1771 13.4688 16.9688C12.9271 16.7396 12.37 16.5625 11.7975 16.4375C11.2242 16.3125 10.625 16.25 10 16.25C9.375 16.25 8.77583 16.3125 8.2025 16.4375C7.63 16.5625 7.07292 16.7396 6.53125 16.9688C6.05208 17.1771 5.67708 17.4896 5.40625 17.9062C5.13542 18.3229 5 18.7812 5 19.2812V20Z"
                    fill="#DAA71D"
                  />
                </svg>
              </span> -->
            </div>
            <span class="error" v-if="paramsErrorTxt !== ''">{{
              paramsErrorTxt
            }}</span>
            <div class="input-field-row">
              <label class="txt-label plans">{{ $t("_enter_amount") }}</label>

              <span class="plan-btn" v-if="isOfferStatus || isPlans">
                <RoviButton
                  kind="elevated"
                  :colorConfig="{
                    backgroundColor: mainColors.yellow,
                    borderColor: 'trarnsparent',
                    color: '#ffffff',
                    edgeColors: {
                      right: '#8b6708',
                      bottom: '#624c10',
                    },
                  }"
                  size="smalll"
                  :spacingConfig="{
                    padding: '8px',
                  }"
                  :textStyle="{
                    fontSize: '10px',
                  }"
                  :customStyle="{
                    marginTop: '0px',
                    textTransform: 'captialize',
                  }"
                  @click.native="selectPlan"
                >
                  {{ $t("_browse_plans") }}
                </RoviButton>
              </span>

              <input
                type="text"
                name="amount"
                value=""
                placeholder="0"
                class="input_bg"
                v-model="amount"
                :readonly="billDetails && billDetails.Partial == 'N'"
              />
            </div>
            <span class="error" v-if="amountErrorTxt !== ''">{{
              amountErrorTxt
            }}</span>
            <div class="input-field-row btn-field-row">
              <RoviButton
                kind="flat"
                :disabled="
                  amount == '' ||
                  parseFloat(amount) <= 0 ||
                  billerFieldVal == '' ||
                  selectedOperator == null
                "
                showArrow
                fullWidth
                :colorConfig="{
                  backgroundColor: mainColors.yellow,
                  borderColor: 'trarnsparent',
                  color: '#ffffff',

                  edgeColors: {
                    top: '#8b6708',
                    left: '#624c10',
                  },

                  disabledColors: {
                    backgroundColor: '#e9e9e9',
                    borderColor: '#d9d9d9',
                    color: '#b4b4b4',
                    edgeColors: {
                      top: '#251f39',
                      left: '#251f39',
                    },
                  },
                }"
                size="big"
                :spacingConfig="{
                  padding: '16px 24px',
                }"
                :customStyle="{ marginTop: '4px', textTransform: 'uppercase' }"
                :textStyle="{
                  fontType: 'caps',
                  fontSize: '16px',
                }"
                @click.native="handlePay"
              >
                {{ $t("_pay") }}
              </RoviButton>
            </div>
          </div>
        </div>
      </div>
      <!-- <payment-status-modal /> -->
    </section>
    <div class="bottom-rovi">
      <span class="rovi-plant">
        <rovi-plant />
      </span>
      <span class="rovi-man">
        <mobile-man />
      </span>
    </div>
    <loader-comp :isLoading="isLoading" />
    <offer-model
      v-if="isOfferStatus"
      @closeModal="handleClose"
      @selectedPlan="setSelectedPlanAmt"
      :isOfferStatus="isOfferStatus"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import mobileMan from "@/components/svgIcons/mobileMan.vue";
import roviPlant from "@/components/svgIcons/roviPlant.vue";
import { mapState } from "vuex";
import LoaderComp from "../components/common/LoaderComp.vue";
// import PaymentStatusModal from "@/components/common/PaymentStatusModal.vue";
import OfferModel from "@/components/common/OfferModel.vue";
import {
  mainColors,
  colorPalette,
  fontNameSpaces,
} from "neopoprovi_vue2/src/primitives/";
export default {
  name: "TypeCategory",
  components: {
    mobileMan,
    roviPlant,
    LoaderComp,
    // PaymentStatusModal,
    OfferModel,
  },
  computed: {
    ...mapState([
      "category",
      "operators",
      "billerParams",
      "dashboard",
      "selectedOperator",
      "billDetails",
      "apiSuccess",
      "mobilePlans",
      "payloadToPay",
      "refillItem",
      "categories",
    ]),
    fontNameSpaces() {
      return fontNameSpaces;
    },
    mainColors() {
      return mainColors;
    },
    colorPalette() {
      return colorPalette;
    },
  },
  data() {
    return {
      // selectedOperator: null,
      isLoading: false,
      billerFieldVal: "",
      paramsErrorTxt: "",
      amountErrorTxt: "",
      amount: "",
      billExist: false,
      isPlans: false,
      isOfferStatus: false,
      image: "",
      category_name: "",
      isLoad: false,
    };
  },
  watch: {
    async selectedOperator() {
      if (this.selectedOperator) {
        // this.isLoading = true;
        await this.$store.dispatch(
          "fetchBillerParams",
          this.selectedOperator.BillerID
        );
        // this.isLoading = false;
      }
    },
  },
  methods: {
    handleClose(payload) {
      this.isOfferStatus = payload;
      this.isPlans = true;
    },

    setSelectedPlanAmt(payload) {
      this.amount = payload;
      this.isOfferStatus = false;
      this.isPlans = true;
    },

    async handleBlur(e) {
      console.log("bjdjsd", e.target.value);
      if (e.target.value !== "") {
        if (this.selectedOperator.BillFetch) {
          this.$toast.info("Please wait while we fetching your bill amount..");
          this.isLoading = true;
          console.log(this.selectedOperator);
          await this.$store.dispatch("fetchBillDetails", {
            mobile: this.dashboard.phone_number,
            code: this.selectedOperator.BillerCode,
            account: this.billerFieldVal,
            provider: this.selectedOperator.BillerName,
            category_id: this.category
              ? this.category.category_id
              : this.refillItem.category_id,
          });
          this.isLoading = false;
          this.amount = this.billDetails ? this.billDetails.Amount : "";
          if (this.billDetails) this.billExist = true;
          else this.billExist = false;
        }
        this.isPlans = false;
        this.selectPlan();
      }
    },
    async selectPlan() {
      if (!RegExp(this.billerParams.Pattern).test(this.billerFieldVal)) {
        this.paramsErrorTxt = `Please enter a valid ${this.billerParams.Name}`;
      } else {
        this.paramsErrorTxt = "";
        if (this.category && this.category.category_id == 1) {
          this.isLoading = true;
          let operator = await this.$store.dispatch(
            "fetchOperator",
            this.billerFieldVal
          );
          console.log(operator);
          if (operator && operator.circle.code) {
            this.isOfferStatus = true;
            await this.$store.dispatch("fetchPlans", {
              phone_number: this.billerFieldVal,
              code: operator.circle.code,
              operator_code: operator.operator_code,
            });
          }
          this.isLoading = false;
        }
      }
    },
    handleOperator() {
      this.$router.push("/type-operator");
    },
    async handlePay() {
      this.amount = this.amount.toString().includes(",")
        ? this.amount.replaceAll(",", "")
        : this.amount;
      if (
        !RegExp(this.billerParams.Pattern).test(this.billerFieldVal) ||
        this.billerFieldVal == ""
      ) {
        this.paramsErrorTxt = `Please enter a valid ${
          this.billerParams.Name || "value"
        }`;
      } else if (this.amount == "" || parseFloat(this.amount) < 10) {
        console.log(this.amount, parseFloat(this.amount) < 10);
        this.paramsErrorTxt = "";
        this.amountErrorTxt = `Amount should be greater than equals to 10`;
      } else if (this.amount > 50000) {
        this.paramsErrorTxt = "";
        this.amountErrorTxt = `Amount cannot be greater than Rs. 50,000`;
      } else if (this.amount > this.dashboard.fiat_balance) {
        this.$toast.error("You don't have enough wallet balance.");
      } else {
        this.paramsErrorTxt = "";
        this.amountErrorTxt = ``;
        this.isLoading = true;
        if (
          (this.category &&
            (this.category.category_id == 2 ||
              this.category.category_id == 1 ||
              this.category.category_id == 4)) ||
          (this.refillItem &&
            (this.refillItem.category_id == 2 ||
              this.refillItem.category_id == 1 ||
              this.refillItem.category_id == 4))
        ) {
          this.$store.commit("SET_PAYLOAD_TO_PAY", {
            biller_id: this.selectedOperator.BillerCode,
            biller_details: {
              id: this.selectedOperator.BillerID,
              name: this.selectedOperator.BillerName,
              amount: this.amount,
              number: this.billerFieldVal,
            },
            category_id: this.category
              ? this.category.category_id
              : this.refillItem.category_id,
            type: this.category
              ? this.category.category_name
              : this.refillItem.category,
            amount: this.amount,
            use_wallet: 1,
            use_bonus_wallet: 0,
          });
        } else {
          let payload = {
            biller_id: this.selectedOperator.BillerCode,
            biller_details: {
              id: this.selectedOperator.BillerID,
              name: this.selectedOperator.BillerName,
              amount: this.amount,
              account_num: this.billerFieldVal,
            },
            amount: this.amount,
            number: this.dashboard.phone_number,
            category_id: this.category
              ? this.category.category_id
              : this.refillItem.category_id,
            type: this.category
              ? this.category.category_name
              : this.refillItem.category,
            use_wallet: 1,
            use_bonus_wallet: 0,
          };
          if (this.billDetails && this.billDetails.BillDueDate)
            payload.biller_details.due_date = this.billDetails.BillDueDate;
          if (this.billDetails && this.billDetails.BillRefNumber)
            payload.biller_details.bill_ref_number =
              this.billDetails.BillRefNumber;
          this.$store.commit("SET_PAYLOAD_TO_PAY", payload);
        }

        await this.$store.dispatch("getPreview", this.payloadToPay);
        this.isLoading = false;
        this.$router.push("/payments");
      }
    },
  },
  async mounted() {
    this.$store.commit("SET_BILL_DETAILS", null);
    console.log(" enter");
    this.isLoading = true;
    this.isLoad = true;
    this.isLoad = false;
    setTimeout(() => {
      this.categories.map(async (n) => {
        if (n.category_id == this.$route.params.category) {
          console.log(n);
          this.$store.commit("SET_REFILL", null);
          this.$store.commit("SET_CATEGORY", n);
          this.image = "";
          this.category_name = "";
          // this.isLoading = true;
          await this.$store.dispatch(
            "fetchOperators",
            this.category
              ? this.category.category_id
              : this.refillItem.category_id
          );
          this.isLoading = false;
          this.isLoad = true;
          let operator;
          if (this.operators.length > 0 && this.refillItem) {
            operator = this.operators.filter(
              (n) => n.BillerCode == this.refillItem.request_body.biller_id
            );
            this.$store.commit(
              "SET_OPERATOR",
              operator.length > 0 ? operator[0] : null
            );
            this.billerFieldVal =
              this.refillItem.category_id == 2 ||
              this.refillItem.category_id == 1 ||
              this.refillItem.category_id == 4
                ? this.refillItem.request_body.biller_details.number
                : this.refillItem.request_body.biller_details.account_num;
            this.amount = this.refillItem.request_body.amount;
          }

          if (this.selectedOperator) {
            // this.isLoading = true;
            await this.$store.dispatch(
              "fetchBillerParams",
              this.selectedOperator.BillerID
            );

            // this.isLoading = false;
          }
          if (this.refillItem) {
            this.image = this.refillItem.category_image;
            this.category_name = this.refillItem.category;

            console.log(this.selectedOperator);
            if (this.selectedOperator && this.selectedOperator.BillFetch) {
              this.$toast.info(
                "Please wait while we fetching your bill amount.."
              );
              this.isLoading = true;
              console.log("this.selectedOperator", this.selectedOperator);
              await this.$store.dispatch("fetchBillDetails", {
                mobile: this.dashboard.phone_number,
                code: this.selectedOperator.BillerCode,
                account: this.billerFieldVal,
                provider: this.selectedOperator.BillerName,
                category_id: this.category
                  ? this.category.category_id
                  : this.refillItem.category_id,
              });
              this.isLoading = false;
              this.amount = this.billDetails ? this.billDetails.Amount : "";
              if (this.billDetails) this.billExist = true;
              else this.billExist = false;
            }
          } else {
            this.image = this.category.image;
            this.category_name = this.category.category_name;
          }
        }
      });
    }, 200);
  },

  beforeRouteEnter(to, from, next) {
    if (from.path == "/") {
      next((vm) => {
        if (vm.$store.state.refillItem == null) {
          vm.$store.commit("SET_OPERATOR", null);
        }
      });
    }
    next();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/type-category.scss";
.plans {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.back-btn {
  margin: 0 4px 0 -8px;
  svg {
    height: 32px;
    width: auto;
  }
}
.txt-label {
  // display: flex;
  white-space: nowrap;
}
.fetch-txt {
  color: #777;
  white-space: nowrap;
  font-size: 0.85em;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}
.error {
  padding: 0 16px;
  font-size: 0.85em;
  color: red;
}
</style>
