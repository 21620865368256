import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import TypeCategory from "../views/TypeCategory.vue";
import TypeOperator from "../views/TypeOperator.vue";
import TransactionHistory from "../views/TransactionHistory.vue";
import PaymentView from "../views/PaymentView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "HomeView",
    component: HomeView,
  },

  {
    path: "/category/:category",
    name: "TypeCategory",
    component: TypeCategory,
  },

  {
    path: "/type-operator",
    name: "TypeOperator",
    component: TypeOperator,
  },

  {
    path: "/transaction-history",
    name: "TransactionHistory",
    component: TransactionHistory,
  },

  {
    path: "/payments",
    name: "PaymentView",
    component: PaymentView,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
