<template>
  <div class="payment-status">
    <div class="custom-overlay"></div>
    <div class="status-mdl-box process-status">
      <!-- <span class="close-btn" @click="handleClose">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path
            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"
          />
        </svg>
      </span> -->

      <div class="status-info-box">
        <div class="status-img-box">
          <img
            v-if="paymentDetails.status == 'success'"
            src="@/assets/images/sucessfully.png"
            width=""
            height=""
            alt="sucessfully"
          />

          <img
            v-if="paymentDetails.status == 'failed'"
            src="@/assets/images/failed.svg"
            width=""
            height=""
            alt="failed"
          />
          <img
            class="pending-img"
            v-if="paymentDetails.status == 'pending'"
            src="@/assets/images/pending.png"
            width=""
            height=""
            alt="pending"
          />
        </div>

        <div class="status-title">
          {{
            paymentDetails.status[0].toUpperCase() +
            paymentDetails.status.substring(1)
          }}
        </div>

        <div class="status-amt">
          Amount: <span class="txt-amt">₹{{ paymentDetails.amount }}</span>
        </div>

        <div class="status-id" v-if="paymentDetails.req_id">
          Transaction id: <span>#{{ paymentDetails.req_id }}</span>
        </div>

        <!-- <div class="status-btn-blk" @click="handleGoHome">
          <span class="txt-title">{{
            paymentDetails.status == "success" ? "Go To Home" : "Go Back"
          }}</span>
          <span class="icon-btn nxt-btn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path
                d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8-8-8z"
              />
            </svg>
          </span>
        </div> -->
        <RoviButton
          kind="elevated"
          :colorConfig="{
            backgroundColor: mainColors.yellow,
            borderColor: 'trarnsparent',
            color: '#ffffff',
            edgeColors: {
              right: '#8b6708',
              bottom: '#624c10',
            },
          }"
          size="big"
          :textStyle="{
            fontType: 'caps',
            fontSize: '16px',
          }"
          :spacingConfig="{
            padding: '16px 8px',
          }"
          :customStyle="{
            textTransform: 'uppercase',
          }"
          fullWidth
          showShimmer
          showArrow
          @click.native="handleGoHome"
        >
          {{ paymentDetails.status == "success" ? "Go To Home" : "Go Back" }}
        </RoviButton>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mainColors,
  colorPalette,
  fontNameSpaces,
} from "neopoprovi_vue2/src/primitives/";

export default {
  props: ["paymentDetails"],
  computed: {
    fontNameSpaces() {
      return fontNameSpaces;
    },
    mainColors() {
      return mainColors;
    },
    colorPalette() {
      return colorPalette;
    },
  },
  methods: {
    handleClose() {
      this.$emit("closeModal", true);
    },
    handleGoHome() {
      // this.$router.push("/");
      if (this.paymentDetails.status == "failed") this.$router.go(-1);
      else window.location.replace("/?q=123");
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/reset.scss";
.payment-status {
  img {
    width: 150px;
    height: 150px;
  }
  .custom-overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    padding: 16px;
    background-color: #000000d6;
    z-index: 11;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
  }

  .status-mdl-box {
    width: 90%;
    position: fixed;
    left: 50%;
    top: 50%;
    height: auto;
    padding: 16px;
    background: #fff;
    transform: translate(-50%, -50%);
    z-index: 11;
    border-radius: 16px;
  }

  .close-btn {
    width: 36px;
    height: 36px;
    border-radius: 100px;
    background: #ffffff;
    box-shadow: 0px 8px 24px #33333314;
    position: absolute;
    left: auto;
    right: -16px;
    top: -16px;
    svg {
      width: auto;
      height: 24px;
      fill: #000000;
      @include posXYcenter();
    }
  }

  .status-info-box {
    width: 100%;
    display: block;
  }

  .status-img-box {
    width: 100%;
    display: block;
    text-align: center;
    margin: 0 0 16px 0;
    img {
      max-width: 100%;
      height: auto;
    }

    svg {
      width: 100%;
      height: auto;
    }
  }

  .status-title {
    display: block;
    margin: 16px 0 8px;
    font-size: 1.5em;
    color: #000;
    font-weight: bold;
    text-align: center;
  }

  .status-amt {
    display: block;
    text-align: center;
    margin: 20px 0;
    font-size: 1em;
    color: #505050;
    font-weight: 400;
    .txt-amt {
      color: #000000;
      font-weight: 600;
    }
  }

  .status-id {
    display: block;
    text-align: center;
    margin: 8px 0 24px;
    font-size: 1em;
    color: #505050;
    font-weight: 400;
    span {
      font-weight: bold;
    }
  }

  .status-btn-blk {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all ease 0.3s;
    background: #251f39;
    border-radius: 32px;
    padding: 8px;
    margin: 8px 0;

    .txt-title {
      font-size: 1em;
      color: #daa71d;
      font-weight: 600;
      text-transform: uppercase;
      transition: all ease 0.3s;
    }

    .icon-btn {
      svg {
        width: auto;
        height: 24px;
        fill: #daa71d;
        transition: all ease 0.3s;
      }
    }

    &.disable-btn {
      opacity: 0.3;
      .txt-title {
        color: #ffffff75;
      }

      .icon-btn {
        svg {
          fill: #ffffff75;
        }
      }
    }

    &:hover {
      background: #daa71d;
      .txt-title {
        color: #251f39;
      }

      .icon-btn {
        svg {
          fill: #251f39;
        }
      }
    }

    &:active {
      background: #daa71d;
      .txt-title {
        color: #251f39;
      }

      .icon-btn {
        svg {
          fill: #251f39;
        }
      }
    }
  }

  .failed-status {
    background: #fdf3f4;
    .status-img-box {
      margin: 24px 0 16px;
    }
  }

  .process-status {
    .status-img-box {
      margin: 24px 0 16px;
    }
  }
}
</style>
