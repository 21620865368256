import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueToast from "vue-toast-notification";

import Neopoprovi from "neopoprovi_vue2";
import i18n from './i18n'
import "vue-toast-notification/dist/theme-sugar.css";

Vue.config.productionTip = false;

Vue.use(VueToast, {
  position: "top-right",
});

Vue.use(Neopoprovi);
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
