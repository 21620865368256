<template>
  <div class="app-view transaction-view">
    <header>
      <div class="top-head">
        <span class="icon-btn back-btn" @click="$router.go(-1)">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z" />
          </svg>
        </span>
        <h1 class="logo">{{ $t("_select_operators") }}</h1>
      </div>
    </header>
    <section class="mid-cnt">
      <div class="search-box">
        <input
          type="text"
          name="search"
          class="input-search"
          value=""
          :placeholder="$t('search_operator')"
          v-model="search"
        />
        <span class="search-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path
              d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
            />
          </svg>
        </span>
      </div>

      <div class="operator-list">
        <ul>
          <li v-for="(operator, i) in filteredOperators" :key="i">
            <label>
              <input
                type="radio"
                name="radio"
                class="input_radio"
                :value="operator"
                v-model="selectedOperator"
              />
              <div class="select-type-tab" @click="handleClick(operator)">
                <span class="radio-btn"></span>
                <span class="txt-title"> {{ operator.BillerName }} </span>
              </div>
            </label>
          </li>
        </ul>
      </div>
    </section>
    <div class="bottom-rovi">
      <span class="rovi-plant">
        <rovi-plant />
      </span>
      <span class="rovi-man">
        <mobile-man />
      </span>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import mobileMan from "@/components/svgIcons/mobileMan.vue";
import roviPlant from "@/components/svgIcons/roviPlant.vue";
import { mapState } from "vuex";

export default {
  name: "TypeOperator",
  components: {
    mobileMan,
    roviPlant,
  },
  computed: {
    ...mapState(["operators", "selectedOperator", "category"]),
    filteredOperators() {
      return this.operators && this.operators.length > 0
        ? this.operators.filter((n) => {
            return n.BillerName.toLowerCase().includes(
              this.search.toLowerCase()
            );
          })
        : this.operators;
    },
  },
  data() {
    return {
      search: "",
    };
  },
  methods: {
    handleClick(operator) {
      this.$store.commit("SET_OPERATOR", operator);
      setTimeout(() => {
        // this.$router.push("/type-category");
        this.$router.push("/category/" + this.category.category_id);
      }, 400);
    },
  },
  mounted() {
    if (this.category == null) {
      this.$router.push("/");
    }
    console.log("mounted");
    this.selectOperator = this.selectedOperator;
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/type-operator.scss";
</style>
