<template>
  <div class="app-view home-view" v-if="dashboard">
    <header>
      <div class="top-head">
        <h1 class="logo">{{ $t("_bills91") }}</h1>
      </div>

      <ul class="category-tab-blk">
        <li
          v-for="(tab, i) in tabs"
          :key="i"
          @click="handleTab(tab)"
          :class="{ 'active-category': selectedTab == tab }"
        >
          {{ tab }}
        </li>
      </ul>
    </header>
    <section class="mid-cnt">
      <div
        class="wallet-blk"
        v-if="dashboard && selectedTab == 'Recharge & Pay Bills'"
      >
        <RoviCard
          kind="elevated"
          backgroundColor="#000000"
          :edgeColors="{
            bottom: '#515151',
            right: '#858585',
          }"
          :customStyle="{
            overflow: 'hidden',
          }"
          fullWidth
          :edgeWidth="4"
        >
          <span class="lft-abstract-bg">
            <svg
              width="81"
              height="83"
              viewBox="0 0 81 83"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M80.8843 83C80.3481 72.8236 77.997 62.8121 73.93 53.442C71.6572 48.2276 68.8771 43.2493 65.63 38.579C62.3892 33.9208 58.709 29.5843 54.64 25.629C50.5481 21.6539 46.0881 18.0762 41.32 14.944C28.893 6.79457 14.7035 1.82872 0 0.41716V30.8875C26.4283 35.101 46.9538 56.5942 48.8559 83H80.8843Z"
                fill="#daa71d"
                opacity="0.5"
              />
            </svg>
          </span>
          <span class="rgt-abstract-bg">
            <svg
              width="104"
              height="111"
              viewBox="0 0 104 111"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M40.68 96.056C55.6276 105.858 73.125 111.055 91 111C95.3588 111.01 99.703 110.708 104 110.102V79.5551C99.9713 80.411 95.7888 80.862 91.5 80.862C59.243 80.862 33 55.348 33 23.987C33 15.4216 34.9576 7.29238 38.4607 0L4.28816 0C2.10982 7.62466 0.997192 15.533 0.997192 23.499C0.997192 35.2166 3.40455 46.8092 8.06997 57.558C10.3428 62.7724 13.1228 67.7507 16.37 72.421C19.6107 77.0792 23.2909 81.4157 27.36 85.371C31.4519 89.3461 35.9119 92.9238 40.68 96.056Z"
                fill="#daa71d"
                opacity="0.2"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M70.7246 61.5252C76.3608 64.4836 82.6346 66.0197 89 66C94.1572 66.0128 99.2537 65.0056 104 63.0494V48.1408C102.507 49.1974 100.896 50.0925 99.1919 50.8049C94.6376 52.7085 89.6221 53.2229 84.7763 52.2834C79.9304 51.3438 75.4706 48.9923 71.9578 45.5245C68.445 42.0567 66.0361 37.6276 65.034 32.7943C64.032 27.9609 64.4817 22.9392 66.3264 18.3607C68.1712 13.7823 71.3286 9.85161 75.4016 7.06308C79.4746 4.27455 84.2813 2.75272 89.217 2.68901C92.7566 2.67038 96.2235 3.39631 99.408 4.78138C96.5054 1.83012 92.4664 0 88 0H61.7186C59.8435 1.81257 58.1481 3.81277 56.661 5.97401C55.2512 8.03175 54.0455 10.2221 53.061 12.514C51.0387 17.2514 49.9961 22.349 49.9961 27.5C49.9961 32.651 51.0387 37.7486 53.061 42.486C54.0455 44.7779 55.2512 46.9683 56.661 49.026C60.2604 54.276 65.0883 58.5669 70.7246 61.5252Z"
                fill="#daa71d"
                opacity="0.8"
              />
            </svg>
          </span>
          <div class="account-balance">
            <div class="account_col">
              <div class="wallet-info">
                <span class="wallet_icn">
                  <img
                    src="@/assets/images/wallet_icn.png"
                    width=""
                    height=""
                    alt="Wallet"
                /></span>
                <span class="txt-title">{{ $t("_m91_wallet") }}</span>
                <span class="txt-value">{{
                  dashboard.c91WalletBalance.toFixed(2)
                }}</span>
              </div>
            </div>
            <div class="account_col bdr">
              <div class="wallet-info">
                <span class="wallet_icn"
                  ><img
                    src="@/assets/images/wallet_icn.png"
                    width=""
                    height=""
                    alt="Wallet"
                /></span>
                <span class="txt-title">{{ $t("_fiat_wallet") }}</span>
                <span class="txt-value"
                  >र {{ dashboard.fiat_balance.toFixed(2) }}</span
                >
              </div>
            </div>
          </div>
        </RoviCard>
      </div>
      <div
        class="request-type-blk"
        v-if="
          selectedTab == 'Recharge & Pay Bills' &&
          dashboard.requests.length != 0
        "
      >
        <ul v-if="dashboard && dashboard.requests">
          <carousel
            :responsive="{
              0: { items: 1, nav: false },
              600: { items: 1, nav: false },
              1000: { items: 1, nav: false },
            }"
            :autoplayHoverPause="true"
            :autoplay="true"
            :autoplayTimeout="5000"
            :autoHeight="true"
          >
            <li
              v-for="(item, i) in dashboard.requests.filter((n, i) => i < 10)"
              :key="i"
            >
              <RoviButton
                kind="elevated"
                :colorConfig="{
                  backgroundColor: mainColors.yellow,
                  borderColor: 'trarnsparent',
                  color: '#ffffff',
                  edgeColors: {
                    right: '#8b6708',
                    bottom: '#624c10',
                  },
                }"
                as="div"
                size="big"
                :spacingConfig="{
                  padding: '16px 12px',
                }"
                :customStyle="{
                  textTransform: 'uppercase',
                }"
                fullWidth
                @click.native="handleRefill(item)"
              >
                <div class="request-elm-bar">
                  <div class="lft-req-item">
                    <div class="txt-block">
                      <div class="category-info">
                        <h2>
                          <span class="cgt-type-operator">{{
                            item.biller_name
                          }}</span>
                          {{ item.request_body.type }} -
                          {{
                            item.request_body.type == "Mobile"
                              ? item.request_body.biller_details.number
                              : item.request_body.biller_details.account_num
                          }}
                        </h2>
                        <!-- <div class="category-status">
                    Plan of ₹99 expires on Tue, 15 Nov 2022
                  </div> -->
                      </div>
                    </div>
                  </div>
                  <div class="rgt-req-item">
                    <div class="txt-block">
                      <div class="category-info">
                        <div class="cgt-type-amt">
                          ₹{{ parseFloat(item.request_body.amount).toFixed(2) }}
                        </div>
                        <div class="txt-btn-blk">
                          <div class="link-button">
                            <span class="txt-link">{{
                              $t("_recharge_now")
                            }}</span>
                            <span class="icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                              >
                                <path d="M0 0h24v24H0V0z" fill="none" />
                                <path
                                  d="M10.02 6L8.61 7.41 13.19 12l-4.58 4.59L10.02 18l6-6-6-6z"
                                />
                              </svg>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </RoviButton>
            </li>
          </carousel>
        </ul>
      </div>

      <div class="category-block" v-if="selectedTab == 'Recharge & Pay Bills'">
        <!-- <div
          class="category-item"
          v-for="(category, i) in sortedCategories()"
          :key="i"
          @click="handleCategory(category)"
        >
          <div class="category-box">
            <div class="category-icon">
              <img :src="category.image" alt="" />
            </div>
            <div class="category-title">
              {{ category.category_name }}
            </div>
          </div>
        </div> -->
        <RoviButton
          kind="flat"
          class="category-item"
          :colorConfig="{
            backgroundColor: '#fff',
            borderColor: '#f0f0f0',
            edgeColors: {
              top: '#f0f0f0',
              left: '#f0f0f0',
            },
          }"
          :textStyle="{}"
          :borderWidth="1.5"
          :edgeWidth="3"
          :customStyle="{
            justifyContent: 'center',
            buttonFaceStyles: {
              width: '100%',
              height: '100%',
              padding: '12px 8px 16px',
            },
          }"
          v-for="(category, i) in sortedCategories()"
          :key="i"
          @click.native="handleCategory(category)"
        >
          <Column class="v-center">
            <div class="category-icon">
              <img :src="category.image" alt="" />
            </div>
            <div class="category-title">
              {{ category.category_name }}
            </div>
          </Column>
          <!-- <div class="">
              <div class="category-icon">
                <img :src="category.image" alt="" />
              </div>
              <div class="category-title">
                {{ category.category_name }}
              </div>
            </div> -->
        </RoviButton>
      </div>
      <transaction-history
        v-if="selectedTab == 'Recent Transaction'"
        :selectedTab="selectedTab"
      />
    </section>
    <loader-comp :isLoading="isLoading" />
  </div>
</template>

<script>
// @ is an alias to /src
import LoaderComp from "../components/common/LoaderComp.vue";
import { mapState } from "vuex";
import TransactionHistory from "./TransactionHistory.vue";
import carousel from "vue-owl-carousel";

import {
  mainColors,
  colorPalette,
  fontNameSpaces,
} from "neopoprovi_vue2/src/primitives/";

export default {
  name: "HomeView",
  components: {
    LoaderComp,
    TransactionHistory,
    carousel,
  },
  computed: {
    ...mapState(["categories", "dashboard"]),
    fontNameSpaces() {
      return fontNameSpaces;
    },
    mainColors() {
      return mainColors;
    },
    colorPalette() {
      return colorPalette;
    },
  },
  data() {
    return {
      tabs: ["Recharge & Pay Bills", "Recent Transaction"],
      selectedTab: "",
      isLoading: false,
    };
  },
  methods: {
    handleRefill(item) {
      console.log(item);
      this.$store.commit("SET_REFILL", item);
      this.$store.commit("SET_CATEGORY", null);
      this.$router.push("/type-category");
    },
    handleTab(tab) {
      this.selectedTab = tab;
    },
    handleCategory(category) {
      console.log(category.category_id);
      this.$store.commit("SET_REFILL", null);
      this.$store.commit("SET_CATEGORY", category);
      this.$router.push("/category/" + category.category_id);
    },
    sortedCategories() {
      return this.categories.slice().sort(function (a, b) {
        return a.order - b.order;
      });
    },
  },
  async mounted() {
    this.selectedTab = this.tabs[0];
    this.isLoading = true;

    await this.$store.dispatch("fetchDashboard");
    await this.$store.dispatch("fetchTransactions");
    this.isLoading = false;
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/home.scss";
</style>
