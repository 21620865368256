export const mutations = {
  API_SUCCESS(state, payload) {
    state.apiSuccess = payload;
  },
  ERR_MSG(state, payload) {
    state.errorMessage = payload;
  },
  SET_CATEGORIES(state, payload) {
    state.categories = payload;
  },
  SET_CATEGORY(state, payload) {
    state.category = payload;
  },
  SET_OPERATORS(state, payload) {
    state.operators = payload;
  },
  SET_BILLER_PARAMS(state, payload) {
    state.billerParams = payload;
  },
  SET_PREVIEW(state, payload) {
    state.preview = payload;
  },
  SET_DASHBOARD(state, payload) {
    state.dashboard = payload;
  },
  SET_PAYLOAD_TO_PAY(state, payload) {
    state.payloadToPay = payload;
  },
  SET_OPERATOR(state, payload) {
    state.selectedOperator = payload;
  },
  SET_BILL_DETAILS(state, payload) {
    state.billDetails = payload;
  },
  SET_TXNS(state, payload) {
    state.txns = payload;
  },
  SET_MOBILE_PLANS(state, payload) {
    state.mobilePlans = payload;
  },
  SET_REFILL(state, payload) {
    state.refillItem = payload;
  },
};
