<template>
  <div
    class="offer-modal-cnt"
    :class="{ 'open-offer': isOfferStatus }"
    v-if="mobilePlans"
  >
    <div class="view-port">
      <header class="offer-header">
        <div class="top-head-blk">
          <div class="title-head">{{ $t("_offer_plans") }}</div>
          <span class="close-btn" @click="handleClose">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path
                d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"
              />
            </svg>
          </span>
        </div>
        <div class="type-tab-blk">
          <ul>
            <li
              class="type-item"
              v-for="(tab, i) in tabs"
              :key="i"
              :class="{ 'type-active': tab == selectedTab }"
              @click="handleTab(tab)"
            >
              {{ tab }}
            </li>
          </ul>
        </div>
      </header>

      <section class="modal-mid-cnt">
        <ul class="offer-list">
          <li v-for="(item, i) in mobilePlans[selectedTab]" :key="i">
            <div class="offer-list-blk">
              <div class="offer-lft-item">
                <div class="offer-txt-blk">
                  <div class="plan-data">{{ item.desc }}</div>
                  <!-- <div class="plan-desc">
                    Voice: Unlimited Calls | SMS : 100 SMS/day
                  </div> -->
                  <div class="plan-expiry">Validity : {{ item.validity }}</div>
                </div>
              </div>

              <div class="offer-rgt-item">
                <div class="plan-btn-blk">
                  <span class="plan-btn">
                    <RoviButton
                      kind="elevated"
                      :colorConfig="{
                        backgroundColor: mainColors.yellow,
                        borderColor: 'trarnsparent',
                        color: '#ffffff',
                        edgeColors: {
                          right: '#8b6708',
                          bottom: '#624c10',
                        },
                      }"
                      size="smalll"
                      :spacingConfig="{
                        padding: '8px 12px',
                      }"
                      :customStyle="{
                        marginTop: '0px',
                        textTransform: 'captialize',
                      }"
                      @click.native="handleAmount(item.rs)"
                    >
                      Rs. {{ item.rs }}
                    </RoviButton>
                  </span>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </section>
    </div>
    <div class="disclaimer-blk">
      <span>{{ $t("_disclaimer_") }}</span
      >{{ $t("_while_we_support_most_recharges_we") }}
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  mainColors,
  colorPalette,
  fontNameSpaces,
} from "neopoprovi_vue2/src/primitives/";

export default {
  props: ["isOfferStatus"],
  computed: {
    ...mapState(["mobilePlans"]),
    fontNameSpaces() {
      return fontNameSpaces;
    },
    mainColors() {
      return mainColors;
    },
    colorPalette() {
      return colorPalette;
    },
  },
  data() {
    return {
      tabs: [],
      selectedTab: "",
    };
  },
  methods: {
    handleClose() {
      this.$emit("closeModal", false);
      console.log("offer modal close");
    },
    handleTab(tab) {
      this.selectedTab = tab;
    },
    handleAmount(amount) {
      this.$emit("selectedPlan", amount);
    },
  },
  watch: {
    mobilePlans() {
      if (this.mobilePlans) {
        this.tabs = Object.keys(this.mobilePlans);
        console.log(this.tabs);
        this.selectedTab = this.tabs[0];
      }
    },
  },
};
</script>
<style lang="scss">
.view-port {
  overflow-y: auto;
  position: relative;
  height: 100vh;
  &::-webkit-scrollbar {
    display: none;
  }
}
.offer-modal-cnt {
  width: 100%;
  height: 100vh;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  transition: all ease-in-out 0.6s;
  transform: translateY(100%);
  opacity: 0;

  .offer-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #fff;
    box-shadow: none;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 22;
    &::after {
      display: none;
    }
  }

  .top-head-blk {
    display: flex;
    width: 100%;
    padding: 12px 16px;
    border-bottom: 1px solid #ebebeb;
    position: relative;
    justify-content: space-between;
    align-items: center;

    .title-head {
      font-size: 1em;
      color: #000000;
      font-weight: bold;
    }
    .close-btn {
      width: 36px;
      height: 36px;
      border-radius: 100px;
      background: #ffffff;
      box-shadow: 0px 8px 24px #33333314;
      position: relative;

      svg {
        width: auto;
        height: 24px;
        fill: #000000;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .type-tab-blk {
    width: 100%;
    display: block;
    background: #f4f6f8;
    ul {
      width: 100%;
      display: flex;
      flex-direction: row;
      overflow-x: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      li {
        width: auto;
        display: flex;
        flex-shrink: 0;
        flex-grow: 0;
        white-space: nowrap;
        padding: 14px 16px 12px 16px;
        position: relative;
        transition: all ease 0.3s;
        color: #999;
        cursor: pointer;
        font-size: 0.875em;
        border-bottom: 3px solid #f4f6f8;
        &.type-active {
          color: #222;
          font-weight: 600;
          border-bottom: 3px solid #daa71d;
          background: #f3f7f8;
        }
      }
    }
  }

  .offer-list {
    width: 100%;
    display: block;
    margin-bottom: 5em;
    li {
      display: block;
      border-bottom: 1px solid #ebebeb;
      background: #fff;
      transition: all ease 0.3s;
      padding: 16px;
      &:last-child {
        border-bottom: none;
      }

      &:active {
        background: #f3f3f336;
      }
    }

    .offer-list-blk {
      width: 100%;
      display: flex;
      .offer-lft-item {
        width: 60%;
        display: flex;
        flex-shrink: 0;
        flex-grow: 0;
        padding: 0 8px 0 0px;
      }

      .offer-rgt-item {
        width: 40%;
        display: flex;
        flex-shrink: 0;
        flex-grow: 0;
        padding: 0 0px 0 8px;
      }

      .offer-txt-blk {
        width: 100%;
        display: flex;
        flex-direction: column;
      }

      .plan-data {
        font-size: 0.875em;
        font-weight: 500;
        color: #000;
        margin-bottom: 0px;
        line-height: 1.375;
      }

      .plan-desc {
        font-size: 0.75em;
        color: #505050;
        margin-bottom: 4px;
        line-height: 1.375;
        font-weight: 500;
      }

      .plan-expiry {
        font-size: 0.75em;
        color: #999;
        margin: 8px 0 0 0;
      }

      .plan-btn-blk {
        width: 100%;
        display: block;
        text-align: right;

        .plan-btn {
          width: auto;
          cursor: pointer;
          // transition: all 0.3s ease;
          // background: #fff;
          // border-radius: 4px;
          // padding: 0px;
          // color: #daa71d;
          // font-size: 1em;
          // font-weight: bold;
          // // border: 2px solid #daa71d;
          display: inline-block;

          // &:hover {
          //   background: #daa71d;
          //   color: #fff;
          // }

          // &:active {
          //   background: #daa71d;
          //   color: #fff;
          // }
        }
      }
    }
  }
  &.open-offer {
    transform: translateY(0%);
    opacity: 1;
  }
}

.disclaimer-blk {
  width: 100%;
  display: block;
  padding: 12px 16px;
  position: fixed;
  top: auto;
  left: 0;
  bottom: 0px;
  background: #e3e1e1;
  font-size: 0.75em;
  line-height: 1.5;
  color: #505050;
  span {
    font-weight: bold;
    color: #000;
  }
}
</style>
