import { BASE_API } from "@/utils/constants";
import axios from "axios";
import VueCookies from "vue-cookies";
import { config, setConfig } from "../utils/constants";

setConfig({
  headers: {
    "X-Auth-Id": VueCookies.get("X-Auth-Id"),
    "jwt-token": VueCookies.get("JWT-TOKEN"),
  },
});

export const actions = {
  async fetchDashboard({ commit }) {
    commit("API_SUCCESS", false);
    commit("ERR_MSG", "");
    try {
      const { data } = await axios.get(
        `${BASE_API}/recharge/v1/dashboard`,
        config
      );
      // console.log(data);
      commit("API_SUCCESS", true);
      if (data) {
        commit("SET_DASHBOARD", data);
      } else {
        commit("ERR_MSG", data.message);
      }
    } catch (e) {
      console.log(e);
    }
  },

  async fetchCategories({ commit }) {
    commit("API_SUCCESS", false);
    commit("ERR_MSG", "");
    try {
      const { data } = await axios.get(
        `${BASE_API}/recharge/v1/categories/list`,
        config
      );
      // console.log(data);
      commit("API_SUCCESS", data.status);
      if (data.status) {
        commit("SET_CATEGORIES", data.data);
      } else {
        commit("ERR_MSG", data.message);
      }
    } catch (e) {
      console.log(e);
    }
  },

  async fetchOperators({ commit }, id) {
    commit("API_SUCCESS", false);
    commit("ERR_MSG", "");
    try {
      const { data } = await axios.get(
        `${BASE_API}/recharge/v1/categories/biller/list/${id}`,
        config
      );
      commit("API_SUCCESS", data.status);
      if (data.status) {
        commit("SET_OPERATORS", data.data);
      } else {
        commit("ERR_MSG", data.message);
      }
    } catch (e) {
      console.log(e);
    }
  },

  async fetchBillerParams({ commit }, billerId) {
    commit("API_SUCCESS", false);
    commit("ERR_MSG", "");
    try {
      const { data } = await axios.get(
        `${BASE_API}/recharge/v1/biller/parameters/${billerId}`,
        config
      );
      // console.log(data);
      commit("API_SUCCESS", data.status);
      if (data.status) {
        commit("SET_BILLER_PARAMS", data.data);
      } else {
        commit("ERR_MSG", data.message);
      }
    } catch (e) {
      console.log(e);
    }
  },

  async fetchBillDetails({ commit }, payload) {
    console.log("PAYLOD :::: ", payload);
    commit("API_SUCCESS", false);
    commit("ERR_MSG", "");
    try {
      const { data } = await axios.get(
        `${BASE_API}/recharge/v1/fetch_bill/${payload.account}/${payload.code}/${payload.mobile}/${payload.category_id}/${payload.provider}`,
        config
      );
      commit("API_SUCCESS", data.status);
      console.log(data);
      if (data.status) {
        commit("SET_BILL_DETAILS", data.data);
      } else {
        commit("ERR_MSG", data.Message || "You don't have any bill yet.");
      }
    } catch (e) {
      console.log(e);
    }
  },

  async getPreview({ commit }, payload) {
    commit("API_SUCCESS", false);
    commit("ERR_MSG", "");
    try {
      const { data } = await axios.post(
        `${BASE_API}/recharge/v1/preview`,
        payload,
        config
      );
      if (data.error) {
        commit("API_SUCCESS", false);
        commit("ERR_MSG", data.error.data.message);
      } else if (data.result && typeof data.result == "object") {
        commit("API_SUCCESS", true);
        commit("SET_PREVIEW", data.result);
      } else {
        commit("ERR_MSG", data.result || data.message);
      }
    } catch (e) {
      console.log(e);
    }
  },

  async makePayment({ commit }, payload) {
    commit("API_SUCCESS", false);
    commit("ERR_MSG", "");
    try {
      const { data } = await axios.post(
        `${BASE_API}/recharge/v1/bill_payment`,
        payload,
        config
      );
      console.log(data);
      if (data.error) {
        commit("API_SUCCESS", false);
        commit("ERR_MSG", data.error.data.message);
      } else if (!data.result.status) {
        commit("ERR_MSG", data.result.Message.Message || data.result.Message);
      }
      commit("API_SUCCESS", data.result.status);
      if (data.result.data) return data.result.data.request_id;
    } catch (e) {
      console.log(e);
    }
  },

  async fetchTransactions({ commit }) {
    commit("API_SUCCESS", false);
    commit("ERR_MSG", "");
    try {
      const { data } = await axios.get(
        `${BASE_API}/recharge/v1/transaction/history/`,
        config
      );
      commit("API_SUCCESS", data.status);
      // console.log(data);
      if (data.status) {
        commit("SET_TXNS", data.data);
      } else {
        commit("ERR_MSG", data.Message);
      }
    } catch (e) {
      console.log(e);
      commit("ERR_MSG", e.message);
    }
  },

  async refreshTransactions({ state, commit }, txnId) {
    commit("API_SUCCESS", false);
    commit("ERR_MSG", "");
    try {
      const { data } = await axios.get(
        `${BASE_API}/recharge/v1/order/status?clientTxnRef=odoo${txnId}`,
        config
      );
      commit("API_SUCCESS", data.status);
      if (data.status) {
        if (data.data.Data.Status != "PENDING") {
          let txnId = data.data.Data.txnid;
          let txnStatus = data.data.Data.Status;

          let newTxns = state.txns.map((txn) => {
            return txn.txn_id == txnId
              ? { ...txn, request_status: txnStatus }
              : txn;
          });
          commit("SET_TXNS", newTxns);
        }
      } else {
        commit("ERR_MSG", data.Message);
      }
    } catch (e) {
      console.log(e);
      commit("ERR_MSG", e.message);
    }
  },

  async fetchOperator({ commit }, number) {
    commit("API_SUCCESS", false);
    commit("ERR_MSG", "");
    try {
      const { data } = await axios.get(
        `${BASE_API}/recharge/v1/mobile/operator/list/${number}`,
        config
      );
      commit("API_SUCCESS", data.status);
      console.log(data);
      if (data.status) {
        return data.data;
      } else {
        commit("ERR_MSG", data.Message);
      }
    } catch (e) {
      console.log(e);
      commit("ERR_MSG", e.message);
    }
  },

  async fetchPlans({ commit }, payload) {
    commit("API_SUCCESS", false);
    commit("ERR_MSG", "");
    try {
      const { data } = await axios.get(
        `${BASE_API}/recharge/v1/mobile/operator/plans/${payload.phone_number}/${payload.operator_code}/${payload.code}`,
        config
      );
      commit("API_SUCCESS", data.status);
      console.log(data);
      if (data.status) {
        commit("SET_MOBILE_PLANS", data.data);
      } else {
        commit("ERR_MSG", data.Message);
      }
    } catch (e) {
      console.log(e);
      commit("ERR_MSG", e.message);
    }
  },
};
