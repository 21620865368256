<template>
  <div>
    <RoviBottomSheet
      @closeBottomSheet="closeOrderTrackBottomSheet"
      :isActive="isBottomSheetActive"
      :journey="journey"
    >
      <RoviCard
        kind="elevated"
        backgroundColor="#ffffff"
        :edgeColors="{
          top: '#bebebe',
        }"
        :customStyle="{
          padding: '0px',
        }"
        fullWidth
        :edgeWidth="4"
      >
        <Column>
          <div class="modal-head">
            <h2>Payment Status</h2>
            <div class="payment-track-status">
              <div class="payment-state">
                <div
                  v-for="(step, index) in journey"
                  :key="index"
                  class="instatus completed"
                >
                  <h3>{{ step[0] }}</h3>
                  <p>{{ new Date(step[1]).toLocaleString() }}</p>
                </div>
              </div>
            </div>
          </div>
        </Column>
      </RoviCard>
    </RoviBottomSheet>
    <div
      class="app-view transaction-view"
      :style="{
        backgroundColor: selectedTab == 'Recent Transaction' ? '#efefef' : null,
      }"
    >
      <!-- <header>
      <div class="top-head">
        <span class="icon-btn back-btn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z" />
          </svg>
        </span>
        <h1 class="logo">Transaction History</h1>
      </div>
    </header> -->
      <section class="mid-cnt">
        <div class="transaction-list">
          <ul>
            <li
              class="blank"
              v-if="sortedTxns.filter((n) => n.request_status).length == 0"
            >
              {{ $t("_no_record_found") }}
            </li>
            <li
              v-else
              v-for="(txn, i) in sortedTxns.filter(
                // (n) => n.request_status && n.request_status == 'PENDING'
                (n) => n.request_status
              )"
              :key="i"
            >
              <RoviCard
                kind="elevated"
                backgroundColor="#ffffff"
                :edgeColors="{
                  bottom: '#bebebe',
                  right: '#bebebe',
                }"
                :customStyle="{
                  overflow: 'hidden',
                  padding: '0px',
                  fontSize: '12px',
                }"
                fullWidth
                :edgeWidth="4"
              >
                <div class="info-transaction">
                  <span class="type-icon">
                    <mobile-icon />
                  </span>
                  <!-- <div class="info-title">Your Recharge Number : 9999347882</div> -->
                  <div class="info-title">
                    {{
                      txn.bills_detail ? txn.bills_detail.bill_details.type : ""
                    }}
                    <span class="billerName"
                      >({{ txn.biller_name }}
                      <span
                        v-if="
                          txn.bills_detail &&
                          txn.bills_detail.bill_details &&
                          txn.bills_detail.biller_details
                        "
                        >{{
                          " - " +
                          (txn.bills_detail.bill_details.number ||
                            txn.bills_detail.bill_details.account_num)
                        }}</span
                      >)</span
                    >
                  </div>

                  <div class="info-amt">
                    {{ $t("_amount") }} ₹<span class="txt-amt">{{
                      txn.amount
                    }}</span>
                  </div>
                  <div class="info-tr-id">
                    {{ $t("_transaction_id") }}
                    <span class="txt-id">#{{ txn.txn_id }}</span>
                  </div>

                  <div class="info-status">
                    <div class="lft-status">
                      <span class="time-stamp">{{
                        new Date(
                          txn.create_time || txn.created_at
                        ).toLocaleString()
                      }}</span>
                    </div>
                    <div class="rgt-status"></div>
                  </div>
                </div>

                <div
                  class="info-status order-status"
                  :class="{
                    'success-status':
                      txn.request_status.includes('SUCCESS') ||
                      txn.request_status.includes('REFUNDED') ||
                      txn.request_status.includes('WALLET DEBITED') ||
                      txn.request_status.includes('REFUND INITIATED') ||
                      txn.request_status.includes('REQUESTED TO DEBIT WALLET'),
                    'pending-status': txn.request_status.includes('PENDING'),
                    'failed-status': txn.request_status.includes('FAILED'),
                  }"
                >
                  <div class="lft-status">
                    <span
                      class="txt-status"
                      :class="{
                        // 'txt-success': txn.request_status.includes('SUCCESS'),
                        // 'txt-pending': txn.request_status.includes('PENDING'),
                        // 'txt-failed': txn.request_status.includes('FAILED'),
                      }"
                    >
                      {{ txn.request_status }}</span
                    >
                  </div>
                  <div class="rgt-status">
                    <div class="status-blk">
                      <span
                        class="refresh-btn"
                        @click="checkOrderStatus(txn.txn_id, i)"
                        v-if="txn.request_status === 'PENDING'"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="24px"
                          viewBox="0 0 24 24"
                          width="24px"
                        >
                          <path d="M0 0h24v24H0V0z" fill="none" />
                          <path
                            d="M12 6v3l4-4-4-4v3c-4.42 0-8 3.58-8 8 0 1.57.46 3.03 1.24 4.26L6.7 14.8c-.45-.83-.7-1.79-.7-2.8 0-3.31 2.69-6 6-6zm6.76 1.74L17.3 9.2c.44.84.7 1.79.7 2.8 0 3.31-2.69 6-6 6v-3l-4 4 4 4v-3c4.42 0 8-3.58 8-8 0-1.57-.46-3.03-1.24-4.26z"
                          />
                        </svg>
                      </span>

                      <div
                        class="status-view"
                        @click="showTrackStatus(txn.journey, i)"
                      >
                        <span class="txt-title">
                          <!-- स्टेटस देखे -->
                          {{ $t("_view_status") }}
                        </span>
                        <span
                          class="icon-btn nxt-btn"
                          :class="{ 'open-drop': selectedIndex == i }"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="24px"
                            viewBox="0 0 24 24"
                            width="24px"
                          >
                            <path d="M0 0h24v24H0V0z" fill="none" />
                            <path
                              d="M10.02 6L8.61 7.41 13.19 12l-4.58 4.59L10.02 18l6-6-6-6z"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </RoviCard>
            </li>
          </ul>
        </div>
      </section>

      <loader-comp :isLoading="isLoading" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import mobileIcon from "@/components/svgIcons/mobileIcon.vue";
import { mapState } from "vuex";
import LoaderComp from "@/components/common/LoaderComp.vue";
// import OrderTrackBottomSheet from "@/components/common/OrderTrackBottomSheet.vue";
export default {
  name: "TransactionHistorystest",
  props: ["selectedTab"],
  components: {
    mobileIcon,
    LoaderComp,
    // OrderTrackBottomSheet,
  },
  computed: {
    ...mapState(["txns"]),
    sortedTxns() {
      return this.txns.length > 0
        ? // eslint-disable-next-line
          this.txns.sort((a, b) => {
            return a.create_time
              ? new Date(b.create_time) - new Date(a.create_time)
              : "";
          })
        : this.txns;
    },
  },
  data() {
    return {
      isLoading: false,
      selectedIndex: null,
      isBottomSheetActive: false,
      journey: [],
    };
  },
  async mounted() {
    this.isLoading = true;
    await this.$store.dispatch("fetchTransactions");
    this.isLoading = false;
  },

  methods: {
    showTrackStatus(journey, index) {
      console.log(">>>>>>>>>>>>status bar click", index);
      if (document.body.classList.contains("body_hidden"))
        document.body.classList.remove("body_hidden");
      else document.body.classList.add("body_hidden");
      this.isBottomSheetActive = true;
      this.selectedIndex = index;
      this.journey = journey;
    },

    async checkOrderStatus(txnId, index) {
      console.log(">>>>>>>>>>>check refresh status", index);
      this.isLoading = true;
      await this.$store.dispatch("refreshTransactions", txnId);
      this.isLoading = false;
    },
    closeOrderTrackBottomSheet() {
      this.isBottomSheetActive = false;
      this.selectedIndex = null;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/transaction-history.scss";
@import "@/assets/scss/track-status.scss";
.billerName {
  font-size: 0.875em;
  font-weight: 500;
  color: #727272;
}
</style>
