<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style lang="scss"></style>
<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["errorMessage"]),
  },
  watch: {
    errorMessage() {
      if (this.errorMessage !== "") {
        this.$toast.error(this.errorMessage);
      }
    },
  },
  async mounted() {
    await this.$store.dispatch("fetchCategories");
    // try {
    //   window.JSBridgePlugin.showInterstitialOnExit(
    //     '[{"adType":"UNITY","adUnitId":"Android_Interstirial_Ads","accountId":"4292875"}]',
    //     true
    //   );
    // } catch (e) {
    //   console.log("ad error", e);
    // }
  },
};
</script>
