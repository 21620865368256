var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"payment-status"},[_c('div',{staticClass:"custom-overlay"}),_c('div',{staticClass:"status-mdl-box process-status"},[_c('div',{staticClass:"status-info-box"},[_c('div',{staticClass:"status-img-box"},[(_vm.paymentDetails.status == 'success')?_c('img',{attrs:{"src":require("@/assets/images/sucessfully.png"),"width":"","height":"","alt":"sucessfully"}}):_vm._e(),(_vm.paymentDetails.status == 'failed')?_c('img',{attrs:{"src":require("@/assets/images/failed.svg"),"width":"","height":"","alt":"failed"}}):_vm._e(),(_vm.paymentDetails.status == 'pending')?_c('img',{staticClass:"pending-img",attrs:{"src":require("@/assets/images/pending.png"),"width":"","height":"","alt":"pending"}}):_vm._e()]),_c('div',{staticClass:"status-title"},[_vm._v(" "+_vm._s(_vm.paymentDetails.status[0].toUpperCase() + _vm.paymentDetails.status.substring(1))+" ")]),_c('div',{staticClass:"status-amt"},[_vm._v(" Amount: "),_c('span',{staticClass:"txt-amt"},[_vm._v("₹"+_vm._s(_vm.paymentDetails.amount))])]),(_vm.paymentDetails.req_id)?_c('div',{staticClass:"status-id"},[_vm._v(" Transaction id: "),_c('span',[_vm._v("#"+_vm._s(_vm.paymentDetails.req_id))])]):_vm._e(),_c('RoviButton',{attrs:{"kind":"elevated","colorConfig":{
          backgroundColor: _vm.mainColors.yellow,
          borderColor: 'trarnsparent',
          color: '#ffffff',
          edgeColors: {
            right: '#8b6708',
            bottom: '#624c10',
          },
        },"size":"big","textStyle":{
          fontType: 'caps',
          fontSize: '16px',
        },"spacingConfig":{
          padding: '16px 8px',
        },"customStyle":{
          textTransform: 'uppercase',
        },"fullWidth":"","showShimmer":"","showArrow":""},nativeOn:{"click":function($event){return _vm.handleGoHome.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.paymentDetails.status == "success" ? "Go To Home" : "Go Back")+" ")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }