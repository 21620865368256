import Vue from "vue";
import Vuex from "vuex";
import { actions } from "./actions";
import { mutations } from "./mutations";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    apiSuccess: false,
    errorMessage: "",
    categories: [],
    category: null,
    operators: [],
    billerParams: null,
    preview: null,
    dashboard: null,
    payloadToPay: null,
    selectedOperator: null,
    billDetails: null,
    txns: [],
    mobilePlans: null,
    refillItem: null
  },
  getters: {},
  mutations,
  actions,
  modules: {},
});
