<template>
  <div class="app-view payment-view" v-if="preview">
    <header>
      <div class="top-head">
        <span class="icon-btn back-btn" @click="$router.go(-1)">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z" />
          </svg>
        </span>
        <h1 class="logo">{{ $t("_payments") }}</h1>
      </div>
    </header>
    <section class="mid-cnt">
      <div class="summary-box">
        <div class="element-grid payment-title">
          <div class="lft-item">
            <div class="txt-title">{{ $t("_recharge_amount") }}</div>
          </div>
          <div class="rgt-item">
            <div class="txt-value">
              ₹<span class="txt-amt">{{ preview.amount }}</span>
            </div>
          </div>
        </div>

        <div class="discount-blk">
          <span class="icon">
            <svg
              width="20"
              height="14"
              viewBox="0 0 20 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 0L1 0C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1L0 5H0.893C1.889 5 2.813 5.681 2.973 6.664C3.02174 6.95104 3.00726 7.24525 2.93056 7.52612C2.85387 7.80698 2.71681 8.06772 2.52894 8.29015C2.34108 8.51258 2.10694 8.69133 1.84287 8.81393C1.57879 8.93654 1.29115 9.00004 1 9H0L0 13C0 13.2652 0.105357 13.5196 0.292893 13.7071C0.48043 13.8946 0.734784 14 1 14L19 14C19.2652 14 19.5196 13.8946 19.7071 13.7071C19.8946 13.5196 20 13.2652 20 13V9H19C18.7089 9.00004 18.4212 8.93654 18.1571 8.81393C17.8931 8.69133 17.6589 8.51258 17.4711 8.29015C17.2832 8.06772 17.1461 7.80698 17.0694 7.52612C16.9927 7.24525 16.9783 6.95104 17.027 6.664C17.187 5.681 18.111 5 19.107 5H20V1C20 0.734784 19.8946 0.48043 19.7071 0.292893C19.5196 0.105357 19.2652 0 19 0ZM9 12H7V10H9V12ZM9 8L7 8V6L9 6V8ZM9 4H7V2H9V4Z"
                fill="#DBA81C"
              />
            </svg>
          </span>

          <span class="txt-title">{{ $t("_apply_discount") }}</span>
        </div>

        <div class="cart-summary">
          <div
            class="element-grid"
            v-for="(offer, i) in preview.offers"
            :key="i"
          >
            <div class="lft-item">
              <div class="txt-title">
                {{ offer.display_name }}
                ({{ dashboard[offer.name] || "0" }})
              </div>
            </div>
            <div class="rgt-item">
              <div class="cart-value">
                <label>
                  <input
                    type="checkbox"
                    name=""
                    :checked="offer.discount !== 0"
                    :id="'applyOffer' + i"
                    @change="handleCheck"
                  />
                  <span class="check-mark"></span>
                </label>
                ₹
                <span class="txt-amt">-{{ offer.discount.toFixed(4) }}</span>
              </div>
            </div>
          </div>
          <div class="element-grid">
            <div class="lft-item">
              <div class="txt-title">
                FEE
                <!-- {{ offer.display_name }}
                ({{ dashboard[offer.name] || "0" }}) -->
              </div>
            </div>
            <div class="rgt-item">
              <div class="cart-value">
                <!-- <label>
                  <input
                    type="checkbox"
                    name=""
                    :checked="offer.discount !== 0"
                    :id="'applyOffer' + i"
                    @change="handleCheck"
                  />
                  <span class="check-mark"></span>
                </label> -->
                ₹
                <span class="txt-amt">{{ preview.commission_fees || 0 }}</span>
              </div>
            </div>
          </div>
          <!-- <div class="element-grid">
            <div class="lft-item">
              <div class="txt-title">Bouns Wallet (205.7)</div>
            </div>
            <div class="rgt-item">
              <div class="cart-value">
                <label>
                  <input type="checkbox" name="" checked />
                  <span class="check-mark"></span>
                </label>
                ₹ <span class="txt-amt">-3.0</span>
              </div>
            </div>
          </div> -->

          <!-- <div class="element-grid">
            <div class="lft-item">
              <div class="txt-title">Wallet discount (0.0)</div>
            </div>
            <div class="rgt-item">
              <div class="cart-value">
                <label>
                  <input type="checkbox" name />
                  <span class="check-mark"></span>
                </label>
                ₹ <span class="txt-amt">-0.0</span>
              </div>
            </div>
          </div>

          <div class="element-grid">
            <div class="lft-item">
              <div class="txt-title">Shopping wallet discount (0.0)</div>
            </div>
            <div class="rgt-item">
              <div class="cart-value">
                <label>
                  <input type="checkbox" name />
                  <span class="check-mark"></span>
                </label>
                ₹ <span class="txt-amt">-0.0</span>
              </div>
            </div>
          </div> -->

          <div class="element-grid total-pay">
            <div class="lft-item">
              <div class="txt-title">{{ $t("_recharge_amount") }}</div>
            </div>
            <div class="rgt-item">
              <div class="txt-value">
                ₹<span class="txt-amt">{{
                  (
                    preview.amount -
                    totalDiscount +
                    (preview.commission_fees || 0)
                  ).toFixed(4)
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pay-type-blk">
        <!-- <h2>Payments</h2> -->
        <!-- <ul>
          <li>
            <label>
              <input type="checkbox" name="pay_type" checked />

              <span class="check-mark"></span>

              <div class="select-pay-type">
                <span class="type-icon">
                  <img
                    src="@/assets/images/paytm-logo.png"
                    width=""
                    height=""
                    alt="Paytm"
                  />
                </span>
                <div class="pay-title">Paytm</div>
              </div>
            </label>
          </li>
        </ul> -->
        <RoviButton
          kind="elevated"
          fullWidth
          showArrow
          showShimmer
          :colorConfig="{
            backgroundColor: '#daa71d',
            borderColor: 'none',
            color: '#ffffff',
            edgeColors: {
              bottom: '#937115',
              right: '#82671e',
            },
          }"
          :textStyle="{
            fontType: 'caps',
            fontSize: '16px',
          }"
          :spacingConfig="{
            padding: '16px',
          }"
          @click.native="handlePay"
        >
          {{ $t("_pay_now") }}
        </RoviButton>
      </div>
    </section>
    <div class="bottom-rovi">
      <span class="rovi-plant">
        <rovi-plant />
      </span>
      <span class="rovi-man">
        <mobile-man />
      </span>
    </div>
    <loader-comp :isLoading="isLoading" />
    <payment-status-modal
      v-if="isPaymentModalOpen"
      :paymentDetails="paymentDetails"
      @closeModal="handleClose"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import mobileMan from "@/components/svgIcons/mobileMan.vue";
import roviPlant from "@/components/svgIcons/roviPlant.vue";
import { mapState } from "vuex";
import LoaderComp from "@/components/common/LoaderComp.vue";
import PaymentStatusModal from "@/components/common/PaymentStatusModal.vue";
import { setConfig } from "../utils/constants";

export default {
  name: "PaymentView",
  computed: {
    ...mapState([
      "preview",
      "payloadToPay",
      "apiSuccess",
      "dashboard",
      "errorMessage",
    ]),
  },
  components: {
    mobileMan,
    roviPlant,
    LoaderComp,
    PaymentStatusModal,
  },
  data() {
    return {
      isLoading: false,
      isPaymentModalOpen: false,
      paymentDetails: null,
      isDiscountApply: true,
      totalDiscount: 0,
      isC91WalletDis: false,
    };
  },
  methods: {
    handleCheck(e) {
      console.log(e.target.checked);
      this.setDiscount();
    },
    setDiscount() {
      this.isC91WalletDis = false;
      let discounts = this.preview.offers.map((n, i) => {
        if (document.getElementById("applyOffer" + i).checked) {
          if (n.name == "c91WalletBalance") this.isC91WalletDis = true;
          else this.isC91WalletDis = false;
          return n.discount;
        } else {
          return 0;
        }
      });
      this.totalDiscount = discounts.reduce(this.func);
    },
    async handlePay() {
      if (this.preview.amount > this.dashboard.fiat_balance) {
        this.$toast.error("You don't have enough wallet balance.");
        // this.$toast.error("Amount should be greater than 1");
      } else {
        // console.log(this.totalDiscount, this.isC91WalletDis);
        let _this = this;
        try {
          window._JWT.refereshToken = (res) => {
            if (res != "fail") {
              setConfig({
                headers: {
                  "X-Auth-Id": _this.$cookies.get("X-Auth-Id"),
                  "jwt-token": res,
                },
              });
              _this.$cookies.set("JWT-TOKEN", res);
            }
          };
          window.JSBridgePlugin.refreshTokens("window._JWT.refereshToken");
        } catch (err) {
          console.log(err);
        }
        this.isLoading = true;
        this.payloadToPay.amount = this.preview.amount.toString();
        this.payloadToPay.biller_details.amount =
          this.preview.amount.toString();
        this.payloadToPay["req_id"] = this.preview.request_id;
        this.payloadToPay.use_bonus_wallet = this.isC91WalletDis ? 1 : 0;
        if (this.preview.commission_fees) {
          this.payloadToPay["commission_fee"] = this.preview.commission_fees;
        }
        let req_id = await this.$store.dispatch(
          "makePayment",
          this.payloadToPay
        );
        this.isLoading = false;
        if (this.apiSuccess) {
          this.isPaymentModalOpen = true;
          let amount;
          if (this.preview.commission_fees)
            amount =
              parseFloat(this.preview.amount) +
              parseFloat(this.preview.commission_fees);
          else amount = parseFloat(this.preview.amount);
          this.paymentDetails = {
            status: "success",
            amount,
            req_id,
          };
          // this.$toast.success("Payment Successfull!");
          // setTimeout(() => {
          //   this.$router.push("/");
          // }, 1500);
        }
        if (this.errorMessage.includes("Failed")) {
          this.isPaymentModalOpen = true;
          this.paymentDetails = {
            status: "failed",
            amount: this.preview.amount,
          };
        }
        if (this.errorMessage.includes("Pending")) {
          this.isPaymentModalOpen = true;
          this.paymentDetails = {
            status: "pending",
            amount: this.preview.amount,
          };
        }
      }
    },
    func(total, num) {
      return total + num;
    },
    handleClose(payload) {
      this.isPaymentModalOpen = !payload;
    },
  },
  mounted() {
    if (this.preview == null) this.$router.go(-1);
    this.setDiscount();
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/payments.scss";
</style>
